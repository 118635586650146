@import '../../styles/variables.scss';

.relationcard{
    background-color: white;
    width: 435px;
    padding: 20px;
    border-radius: 30px;
    margin: 20px 0px;
    margin-left: 0;
}

@media(max-width: 470px){
    .relationcard{
        width: 350px;
    }
}

@media(max-width: 390px){
    .relationcard{
        width: 300px;
    }
}

.relationcard__title{
    font-family: $font;
    color: $secondary-color;
    font-size: 14px;
    line-height: 17.5px;
}

.relationcard__chart{
    margin-top: 10px;
    margin-bottom: 20px;
    height: 95px;
}

.relationcard__stats{

}

.stat{

}

.stat__row{
    display: flex;
    align-items: center;
}

.stat__row-icon{
    margin-right: 10px;
    width: 13px;
    height: 13px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.stat__row-info{
    font-family: $font;
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 10px;
}