@import '../../styles/variables.scss';

.auth{
    margin: 0 auto;
    margin-top: 50px;
    height: 100vh;
}

.auth__column{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.auth__column-logo{
    margin-bottom: 74px;
    width: 214px;
    height: 86px;
}

.auth__column-form{
    box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.20);
    margin: 0 auto;
    padding: 24px 20px 0px 20px;
}

.auth__column-form-title{
    
    
}

.auth__column-form-title-row{
    display: flex;
    border-bottom: 1px solid $background;
    padding-bottom: 0px;
}

.authform__title-enter-active{
    font-family: $font;
    font-size: 40px;
    font-weight: 700;
    color: $primary-color;
    padding-bottom: 20px;
    border-bottom: 6px solid $primary-color;
    padding-bottom: 20px;
    margin-right: 50px;
    cursor: pointer;
}

.authform__title-reg{
    font-family: $font;
    font-size: 40px;
    font-weight: 700;
    color: $blue-color;
    cursor: pointer;
}

.authform__title-enter{
    font-family: $font;
    font-size: 40px;
    font-weight: 700;
    color: $blue-color;
    margin-right: 50px;
    cursor: pointer;
}

.authform__title-reg-active{
    font-family: $font;
    font-size: 40px;
    font-weight: 700;
    color: $primary-color;
    padding-bottom: 20px;
    border-bottom: 6px solid $primary-color;
    padding-bottom: 20px;
    cursor: pointer;
}

@media(max-width: 640px){
    .authform__title-enter-active{
        font-size: 20px;
    }

    .authform__title-reg{
        font-size: 20px;
    }

    .authform__title-enter{
        font-size: 20px;
    }

    .authform__title-reg-active{
        font-size: 20px;
    }
}