@import '../../styles/variables.scss';

.daycard{
    background-color: white;
    width: 435px;
    padding: 20px;
    border-radius: 30px;
    margin: 20px 0;
    margin-left: 0;
}

@media(max-width: 470px){
    .daycard{
        width: 350px;
    }
}

@media(max-width: 390px){
    .daycard{
        width: 300px;
    }
}

.daycard__row-title{
    font-family: $font;
    color: $secondary-color;
    font-size: 14px;
    line-height: 17.5px;
}


.daycard__count{
    font-family: $font;
    color: $hover-color;
    font-size: 40px;
    line-height: 50px;
    font-weight: 700;
    margin-top: 5px;
}

.daycard__plan-title{
    font-family: $font;
    color: $secondary-color;
    font-size: 14px;
    line-height: 17.5px;
    margin-top: 10px;
}

.daycard__chart{
    margin-top: 20px;
    margin-left: 150px;
    height: 187px;
}