@import '../../styles/variables.scss';

.burger{
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: $background;
    background-image: url('../images/burder-icon.png');
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

.openburger{
    align-self: flex-end;
    margin-bottom: 20px;
}