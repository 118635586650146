@import '../../styles/variables.scss';

.analytics{
    height: 100%;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    background-color: rgba(#D9D9D9, $alpha: 0.2);
    padding-bottom: 40px;
}
