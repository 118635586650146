@import '../../styles/variables.scss';

.paginator{
    margin-bottom: 40px;
    margin-top: 20px;
}

.paginator__row{
    display: flex;

    &-1{
        border-radius: 10px 0px 0px 10px;
    }

    &-2{
        
    }

    &-3{
        
    }

    &-4{
        
    }

    &-5{
        
    }

    &-next{
        border-radius: 0px 10px 10px 0px;
    }
}

.pagbutton{
    padding-left: 15px;
    padding-right: 15px;
    border: 1px solid rgba($color: #000000, $alpha: 0.1);
    font-family: $font;
    color: $blue-color;
    font-size: 16px;
    left: normal;
    text-align: center;
    height: 40px;
}

.pagbutton:hover{
    color: $hover-color;
}

.pagbutton:active{
    color: $primary-color;
    background-color: rgba($secondary-color, $alpha: 0.2);
}