.exitbutton{
    width: 40px;
    height: 40px;
    background-color: #DADADA;
    border-radius: 10px;
    margin-left: 5px;
    cursor: pointer;
}

.exitbutton__image{
    margin-left: 5px;
    margin-top: 5px;
}