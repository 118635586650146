@import '../../styles/variables.scss';

.ridelist{
    height: 100%;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    background-color: rgba(#D9D9D9, $alpha: 0.2);
}

@media(max-width: 1260px){
    .ridelist{
        padding-left: 10px;
        padding-right: 10px;
    }
}
