@import '../../styles/variables.scss';

.ridelistsctruct{
    padding-bottom: 20px;
    border-bottom: 1px solid $secondary-color;
}

.ridelistsctruct_sctucture{
    margin-top: 50px;
    margin-left: 10px;
    margin-right: 170px;
    display: flex;
    gap: 20px;
    justify-content: space-between;

    &-id{
        
    }

    &-number{

    }

    &-entry{
        
    }

    &-exit{
        
    }

    &-duration{
        
    }

    &-weight{
        
    }

    &-photo{
        
    }
}

.element{
    font-family: $font;
    color: $secondary-color;
}



@media(max-width: 1320px){
    .ridelistsctruct_sctucture{
        gap: 0px;
    }
}

@media(max-width: 1024px){
    .ridelistsctruct{
        display: none;
    }
}
