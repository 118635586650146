@import '../../styles/variables.scss';

.analyticscards{

}

.analyticscards_title{
    font-family: $font;
    color: $primary-color;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    margin-left: 10px;
    margin-top: 30px;
    
}

.analyticscards__row{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}